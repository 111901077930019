import React from "react";
import {
  SEO,
  PageLayout,
  FadeReveal,
  MarkdownContent,
  Image,
} from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} subfooter={false} className={"about-page"}>
          <Grid
            className={"about-content-container"}
            stackable={true}
            textAlign={"center"}
          >
            <Grid.Column
              width={8}
              textAlign={"left"}
              verticalAlign={"middle"}
              className={"markdown-column"}
            >
              <Header
                as={"h1"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "content_section_header",
                  defaultValue: "About",
                })}
              />
              <FadeReveal duration={1250} triggerOnce={true}>
                <MarkdownContent
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "content_section_description",
                  })}
                />
              </FadeReveal>
            </Grid.Column>
            <Grid.Column width={8} className={"image-column"}>
              <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                <Image
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Content.Image",
                    componentIdentifier: "content_section_image",
                    numToSelect: 1,
                  })}
                  background={false}
                  className={"image"}
                  loadStrategy={"eager"}
                />
              </FadeReveal>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
  }
`;
